'use client';

import { faBars, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar, NavbarItem } from '@pickleballinc/react-ui';
import { Menu } from '@pickleballinc/react-ui';
import NotificationsPopupMobile from '@pickleballinc/ui/components/pickleball-app/NotificationsPopupMobile';
import { User } from '@sentry/nextjs';
import classNames from 'classnames';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { useEffect, useRef, useState } from 'react';

import { NewsCategoryShortInterface } from '@/legacy/utils/types';
import GlobalSearch from '@/modules/main-header/GlobalSearch';
import { Nav } from '@/modules/main-header/Nav';
import { NAVIGATION_ITEMS } from '@/modules/main-header/nav.config';
import { UserProfile } from '@/modules/top-navigation/UserProfile';
import { NotificationLookup } from '@/utils/actions/notifications/getNotifications';
import { BLOG_LABEL } from '@/utils/helpers/constants';

interface MainHeaderProps {
	user: User | undefined;
	searchButtons: any;
	isTournaments: boolean | undefined;
	categories: NewsCategoryShortInterface[];
	notifications: NotificationLookup | undefined;
}

const validBlogPathname = /^\/[a-zA-Z0-9-]+\/[a-zA-Z0-9-]+$/;

export default function MainHeader({ user, searchButtons, isTournaments, categories, notifications }: MainHeaderProps) {
	const [searchShown, setSearchShown] = useState(false);
	const pathname = usePathname();
	const [mobileMenuOpened, setMobileMenuOpened] = React.useState(false);
	const searchRef = useRef(null);
	const [baseURL, setBaseURL] = useState('');

	const pb_host = process.env.NEXT_PUBLIC_APP_ENV === 'dev' ? 'https://pickleballdev.net' : 'https://pickleball.com';

	useEffect(() => {
		setBaseURL(window.location.href);
	}, []);

	const onRouteChangeStart = () => {
		setMobileMenuOpened(false);
	};

	useEffect(() => {
		onRouteChangeStart();
	}, [pathname]);

	useEffect(() => {
		if (mobileMenuOpened) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.removeProperty('overflow');
		}
	}, [mobileMenuOpened]);

	const filteredCategories = categories ? categories.filter((category) => category.slug !== 'news') : [];

	const pathSegments = pathname?.split('/').filter((segment) => segment) || [];
	const categorySlug = pathSegments[0];
	const isBlogPathname = pathname && validBlogPathname.test(pathname) && filteredCategories.some((category) => category.slug === categorySlug);

	return (
		<header className="relative" id="pb-mainheader">
			<div className="relative">
				<Navbar className="sticky top-0 z-50" as="nav" role="navigation">
					<Navbar.Brand
						className={isTournaments ? '!bg-blue-600 before:!bg-blue-600' : ''}
						tag={Link}
						href={
							process.env.NEXT_PUBLIC_APP_ENV === 'dev' ? 'https://pickleballtournamentsdev.net' : 'https://pickleballtournaments.com'
						}
					>
						<h1>
							<img
								src={'https://cdn.pickleball.com/pickleball-app/static/images/pt-logo.svg'}
								height={isTournaments ? 36 : 32}
								width={isTournaments ? 36 : 32}
								alt={isTournaments ? 'Pickleballtournaments.com' : 'Pickleball.com'}
								className="z-40 skew-x-12"
							/>
						</h1>
					</Navbar.Brand>
					<div className="mr-2 sm:mr-6" />

					<Nav />

					<div className="ml-auto mr-1 flex">
						{user ? (
							<NavbarItem className="!inline-flex sm:!hidden">
								<UserProfile user={user} />
							</NavbarItem>
						) : (
							<NavbarItem className="!inline-flex sm:!hidden">
								<a href={`${process.env.NEXT_PUBLIC_SSO_ENDPOINT}/?continue=${encodeURIComponent(baseURL)}`} rel="nofollow">
									Login
								</a>
							</NavbarItem>
						)}

						<div
							className={`!hidden -skew-x-12 items-center justify-center px-7 text-white sm:!inline-flex ${isTournaments ? '!bg-blue-600' : 'bg-experiment'}`}
						>
							<div className="skew-x-12">
								<Menu showArrow={false} placement="bottom" offset={24} width={172}>
									<Menu.Trigger asChild>
										<div className="flex cursor-pointer items-center gap-1.5">
											<span className="relative flex text-sm font-semibold text-white transition-all duration-150 hover:text-opacity-80">
												{BLOG_LABEL}
											</span>
											<FontAwesomeIcon icon={faChevronDown} size="sm" />
										</div>
									</Menu.Trigger>
									<Menu.List className="min-w-[172px] !max-w-[172px] !px-0 !py-2">
										{filteredCategories.map((category) => {
											return (
												<Menu.Item
													key={category.uuid}
													className="cursor-pointer rounded-lg bg-white text-sm font-medium text-gray-900 hover:bg-[#EBE9FE]"
												>
													<Link
														href={`${pb_host}/${category.slug}/all`}
														className={`line-clamp-1 w-full text-ellipsis px-4 py-2 !outline-none ${isBlogPathname && categorySlug === category.slug ? 'underline' : ''}`}
													>
														{category.title}
													</Link>
												</Menu.Item>
											);
										})}
									</Menu.List>
								</Menu>
							</div>
						</div>

						<NavbarItem
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								setSearchShown(!searchShown);
							}}
							className={classNames(
								isTournaments && !searchShown ? '!border-blue-600' : '',
								isTournaments && searchShown ? '!border-blue-600 !bg-blue-600' : ''
							)}
							active={searchShown}
							ref={searchRef}
						>
							<FontAwesomeIcon
								className="sm:!hidden"
								icon={faSearch}
								aria-label="Search"
								title="Search Icon"
								role="button"
								titleId="search-icon-mobile"
							/>
							<FontAwesomeIcon
								className="!hidden sm:!flex"
								size="lg"
								icon={faSearch}
								aria-label="Search"
								title="Search Icon"
								role="button"
								titleId="search-icon-desktop"
							/>
						</NavbarItem>
						{user && (
							<>
								<div className="inline-flex items-center sm:hidden">
									<div className="h-5 w-px -skew-x-12 bg-gray-700"></div>
								</div>
								<div className="inline-flex items-center sm:hidden">
									<NotificationsPopupMobile
										notifications={notifications}
										checkoutLink={`${process.env.NEXT_PUBLIC_CHECKOUT}/pending`}
									/>
								</div>
							</>
						)}

						<NavbarItem className="!inline-flex items-center sm:!hidden" onClick={() => setMobileMenuOpened(!mobileMenuOpened)}>
							<FontAwesomeIcon icon={faBars} />
						</NavbarItem>
					</div>

					{mobileMenuOpened && (
						<div className="fixed inset-0 flex w-full overflow-hidden sm:hidden">
							<div
								aria-hidden="true"
								className="absolute inset-0 bg-white/30 backdrop-blur-md"
								onClick={() => setMobileMenuOpened(false)}
							/>
							<div
								aria-hidden="true"
								className="absolute right-2 top-2 flex size-11 items-center justify-center rounded-md bg-brand-700"
								onClick={() => setMobileMenuOpened(false)}
							>
								<FontAwesomeIcon icon={faTimes} className="text-white" />
							</div>
							<div className="relative z-50 w-[46%] shrink-0 overflow-auto bg-gray-800">
								<ul className="flex flex-col divide-y divide-gray-900">
									<li className="list-none bg-white">
										<Link
											href={`https://${process.env.NEXT_PUBLIC_FRONT_URL}/watch-now`}
											className="block px-6 py-4 text-sm font-bold leading-5 text-gray-900"
										>
											Watch
										</Link>
									</li>
									{NAVIGATION_ITEMS.map((item) => {
										return (
											<React.Fragment key={item.id}>
												{item.children === BLOG_LABEL && filteredCategories ? (
													<>
														{filteredCategories.map((category: any) => (
															<li key={category.uuid} className="list-none">
																<Link
																	href={`${pb_host}/${category.slug}/all`}
																	className="block px-6 py-4 text-sm font-bold leading-5 text-white"
																>
																	{category.title}
																</Link>
															</li>
														))}
													</>
												) : (
													<li className="list-none">
														<Link href={item.href} className="block px-6 py-4 text-sm font-bold leading-5 text-white">
															{item.children}
														</Link>
													</li>
												)}
											</React.Fragment>
										);
									})}
								</ul>
								<ul className="flex flex-col divide-y divide-gray-900">
									<li className="list-none">
										<Link
											href={`https://${process.env.NEXT_PUBLIC_FRONT_URL}`}
											className="block px-6 py-4 text-sm font-bold leading-5 text-white"
										>
											Pickleball.com
										</Link>
									</li>
								</ul>
							</div>
						</div>
					)}
				</Navbar>
			</div>
			{searchShown && (
				<GlobalSearch
					searchVisible={searchShown}
					globalSearchButtons={searchButtons}
					toggleRef={searchRef}
					onHide={() => setSearchShown(false)}
				/>
			)}
		</header>
	);
}
